/* global availableTimePeriods */
import React from 'react';
import Select from 'react-select';
import Routes from 'global/routes';
import Button from 'global/components/Button';
import Message from 'global/components/Message';
import TimePeriodDataTable from 'risk_management/components/time_period/TimePeriodDataTable';
import ConfirmationContainer from 'risk_management/components/time_period/ConfirmationContainer';
import TimePeriodDataTableRow from 'risk_management/components/time_period/TimePeriodDataTableRow';
import DisplayRow from 'risk_management/components/DisplayRow.js';
import styles from 'risk_management/styles/Confirmation';
import ax from 'global/axios';
import _ from 'lodash';

const unit = () => new URLSearchParams(window.location.search).get('unit');
const cancelUrl = () => unit() == 'month' ? '/time_periods/months' : '/time_periods';

class AddTimePeriodContainer extends React.Component {
  constructor(props) {
    const isNewFintechRiskRmf = window.riskEntitySettings.risk_entity_type == 21;
    const isMaturity = window.riskEntitySettings.maturity_model_type && !isNewFintechRiskRmf;

    super(props);
    this.state = {
      currentStep: 0,
      timePeriodToAdd: null,
      previousTimePeriod: null,
      availablePreviousTimePeriods: [],
      previousRiskCategories: [],
      previousRiskSubcategories: [],
      selectedRiskCategoryKeys: new Set(),
      selectedRiskCategoryRatingKeys: new Set(),
      selectedRiskSubcategoryRatingKeys: new Set(),
      selectedMeasurementPointsKeys: new Set(),
      submitting: false,
      optinChecked: true,
      optinVisible: isMaturity,
      rollForwardSelectIndicatorsChecked: false,
      rollForwardRatingJustificationChecked: false,
      rollForwardRiskAppetiteRatingJustificationChecked: false,
      rollForwardWeightJustificationChecked: false,
      rollForwardIndicatorJustificationChecked: false,
      rollForwardAllJustificationChecked: false,
      rollForwardSelfAssessmentConsiderationRatingsChecked: false,
      configurationDisabled: isMaturity
    };

    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
  }

  _next() {
    let currentStep = this.state.currentStep;
    if (currentStep >= 3) {
      currentStep = 4;
    } else if (currentStep == 1 && this.state.selectedRiskCategoryKeys.size == 0) {
      currentStep = 3;
    } else {
      currentStep = currentStep + 1;
    }
    this.setState({
      currentStep: currentStep
    });
    window.scrollTo(0, 0);
  }

  _prev() {
    let currentStep = this.state.currentStep;
    if (currentStep <= 1) {
      currentStep = 1;
    } else if (currentStep == 3 && this.state.selectedRiskCategoryKeys.size == 0){
      currentStep = 1;
    } else {
      currentStep = currentStep - 1;
    }
    this.setState({
      currentStep: currentStep
    });
    window.scrollTo(0, 0);
  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    const anyPreviousTimePeriods = !_.isEmpty(this.state.availablePreviousTimePeriods);
    if(currentStep !== 0 && currentStep < 4 && anyPreviousTimePeriods) {
      return (
        <button
          className="btn btn-primary pull-right"
          type="button" onClick={this._next}>
        Next
        </button>
      );
    }
    return null;
  }

  get previousButton() {
    let currentStep = this.state.currentStep;
    const anyPreviousTimePeriods = !_.isEmpty(this.state.availablePreviousTimePeriods);
    if(currentStep !== 0 && currentStep !== 1 && anyPreviousTimePeriods) {
      return (
        <button
          className="btn btn-secondary"
          type="button" onClick={this._prev}>
        Back
        </button>
      );
    }
    return null;
  }

  handleSelectedTimePeriodChange = async (timePeriodToAdd) => {
    this.setState({
      timePeriodToAdd,
      previousTimePeriod: null,
      currentStep: 0,
      previousRiskCategories: [],
      selectedRiskCategoryKeys: new Set(),
      selectedRiskCategoryRatingKeys: new Set(),
      selectedMeasurementPointsKeys: new Set(),
    });

    const availablePreviousTimePeriods = await getPreviousTimePeriods(timePeriodToAdd);
    this.setState({ availablePreviousTimePeriods });
  };

  handlePreviousTimePeriodChange = async (previousTimePeriod) => {
    this.setState({
      previousTimePeriod,
      previousRiskCategories: [],
      selectedRiskCategoryKeys: new Set(),
      selectedRiskCategoryRatingKeys: new Set(),
      selectedMeasurementPointsKeys: new Set(),
    });

    if (previousTimePeriod) {
      this.setState({
        currentStep: 1,
        previousRiskCategories: previousTimePeriod.availableRiskCategories,
        previousRiskSubcategories: previousTimePeriod.availableRiskSubcategories,
      });
    } else {
      this.setState({
        currentStep: 0
      });
    }
  };

  handleSelectedRiskCategoriesChange = (selectedKeys) => {
    const prevKeys = this.state.selectedRiskCategoryKeys;
    if (!_.isEqual(prevKeys, selectedKeys)) {
      this.setState({ selectedRiskCategoryKeys: new Set(selectedKeys) });
    }
  };

  handleSelectedRiskCategoryRatingsChange = (selectedKeys) => {
    const prevKeys = this.state.selectedRiskCategoryRatingKeys;
    if (!_.isEqual(prevKeys, selectedKeys)) {
      this.setState({ selectedRiskCategoryRatingKeys: new Set(selectedKeys) });
    }
  };

  handleSelectedRiskSubcategoryRatingsChange = (selectedKeys) => {
    const prevKeys = this.state.selectedRiskSubcategoryRatingKeys;
    if (!_.isEqual(prevKeys, selectedKeys)) {
      this.setState({ selectedRiskSubcategoryRatingKeys: new Set(selectedKeys) });
    }
  };

  handleSelectedMeasurementPointsChange = (selectedKeys) => {
    const prevMeasurementPoints = this.state.selectedMeasurementPointsKeys;
    if (!_.isEqual(prevMeasurementPoints, selectedKeys)) {
      this.setState({ selectedMeasurementPointsKeys: new Set(selectedKeys) });
    }
  };

  getSelectedOptions = (options, selectedKeys) => {
    if (selectedKeys.size == 0) {
      return new Array();
    } else {
      let selectedOptions = [];
      options.forEach(function(option) {
        selectedKeys.forEach(function(key) {
          if (option.id == key) {
            selectedOptions.push(option);
          }
        });
      });
      return selectedOptions;
    }
  };

  getSelectedSubcategoryOptions = (options, selectedCategories) => {
    if (selectedCategories.size == 0) {
      return new Array();
    } else {
      let selectedSubcategories = [];
      options.forEach(function(option) {
        selectedCategories.forEach(function(category) {
          if (option.parentDefinitionId == category.definitionId) {
            selectedSubcategories.push(option);
          }
        });
      });
      return [...new Set(selectedSubcategories)];
    }
  };

  measurementPointOptions = () => {
    let options = [
      {id: 'target', name: window.riskEntitySettings.target_label},
      {id: 'peer', name: window.riskEntitySettings.peer_average_label},
      {id: 'policy', name: window.riskEntitySettings.policy_limit_label},
      {id: 'forecasted', name: window.riskEntitySettings.forecasted_value_label},
      {id: 'custom_measurement_point_1', name: window.riskEntitySettings.custom_measurement_point_1_label},
      {id: 'custom_measurement_point_2', name: window.riskEntitySettings.custom_measurement_point_2_label}
    ];

    return options;
  };

  handleFormSubmit = () => {
    this.setState({ submitting: true });
    const timePeriodToAdd = this.state.timePeriodToAdd;
    const previousTimePeriod = this.state.previousTimePeriod;
    const selectedRiskCategoryKeys = this.state.selectedRiskCategoryKeys;
    const selectedRiskCategoryRatingKeys = this.state.selectedRiskCategoryRatingKeys;
    const selectedRiskSubcategoryRatingKeys = this.state.selectedRiskSubcategoryRatingKeys;
    const selectedMeasurementPointsKeys = this.state.selectedMeasurementPointsKeys;
    const optinChecked = this.state.optinChecked;
    const rollForwardSelectIndicatorsChecked = this.state.rollForwardSelectIndicatorsChecked;
    const rollForwardRatingJustificationChecked = this.state.rollForwardRatingJustificationChecked;
    const rollForwardRiskAppetiteRatingJustificationChecked = this.state.rollForwardRiskAppetiteRatingJustificationChecked;
    const rollForwardWeightJustificationChecked = this.state.rollForwardWeightJustificationChecked;
    const rollForwardIndicatorJustificationChecked = this.state.rollForwardIndicatorJustificationChecked;
    const rollForwardSelfAssessmentConsiderationRatingsChecked = this.state.rollForwardSelfAssessmentConsiderationRatingsChecked;

    return ax({
      url: Routes.time_periods_path(),
      method: 'post',
      data: {
        time_period_to_add: timePeriodToAdd.value,
        previous_time_period: _.get(previousTimePeriod, 'value'),
        selected_risk_category_ids: selectedRiskCategoryKeys,
        selected_risk_category_rating_ids: selectedRiskCategoryRatingKeys,
        selected_risk_subcategory_rating_ids: selectedRiskSubcategoryRatingKeys,
        selected_measurement_point_keys: selectedMeasurementPointsKeys,
        opt_in_for_data_collection: optinChecked,
        roll_forward_select_indicators: rollForwardSelectIndicatorsChecked,
        roll_forward_rating_justification: rollForwardRatingJustificationChecked,
        roll_forward_risk_appetite_rating_justification: rollForwardRiskAppetiteRatingJustificationChecked,
        roll_forward_weight_justification: rollForwardWeightJustificationChecked,
        roll_forward_indicator_justification: rollForwardIndicatorJustificationChecked,
        roll_forward_self_assessment_considerations: rollForwardSelfAssessmentConsiderationRatingsChecked
      }
    }).then((_res) => {
      pollUntilReady(timePeriodToAdd);
    }).catch(ax.handleError);
  };

  renderHelpMessage = (timePeriodToAdd, anyPreviousTimePeriods) => {
    if (!timePeriodToAdd) {
      return null;
    }

    const currentStep = this.state.currentStep;

    if (currentStep == 0 || currentStep == 1) {
      if (anyPreviousTimePeriods) {
        return (
          <div className='info-alert-message alert-message-thin'>
            After selecting a previous time period, you can choose which of the following should be rolled forward into {timePeriodToAdd.label}:
            <ul>
              <li>{window.riskEntitySettings.plural_risk_category_label}</li>
              <li>Qualitative Ratings</li>
              <li>{window.riskEntitySettings.plural_measurement_point_label}</li>
            </ul>
            Any category selected will also roll forward any of its sub-categories where applicable.
          </div>
        );
      } else {
        const contents = `There are no available time periods prior to ${timePeriodToAdd.label}.`;
        return (
          <Message
            contents={contents}
            isThin={true}
            type={'warning'}
          />
        );
      }
    }
  };

  renderPreviousTimePeriodSelect = () => {
    const {
      timePeriodToAdd,
      previousTimePeriod,
      availablePreviousTimePeriods,
      currentStep,
      submitting,
    } = this.state;
    const anyPreviousTimePeriods = !_.isEmpty(availablePreviousTimePeriods);

    if (currentStep == 0 || currentStep == 1) {
      return (
        <>
          <div className='form-group select'>
            <label className='select control-label'>Select a previous time period</label>
            <Select
              id={'previousTimePeriodSelect'}
              classNamePrefix='timePeriod'
              value={previousTimePeriod}
              onChange={this.handlePreviousTimePeriodChange}
              options={availablePreviousTimePeriods}
              isDisabled={!timePeriodToAdd || !anyPreviousTimePeriods || submitting}
              isClearable={true}
              placeholder={anyPreviousTimePeriods ? 'Select...' : 'None available'}
              styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
            />
          </div>
        </>
      );
    } else if (previousTimePeriod && currentStep != 4) {
      return (
        <div>
          <ConfirmationContainer
            records={[{id: this.state.previousTimePeriod.label, name: this.state.previousTimePeriod.label}]}
            rowComponent={DisplayRow}
            headerName={['Selected Previous Time Period']}
          />
        </div>
      );
    }
  };

  renderPatienceMessage = () => {
    if (this.state.submitting && this.state.selectedRiskCategoryKeys.size > 0) {
      const contents = `Rolling forward ${window.riskEntitySettings.plural_risk_category_label.toLowerCase()} may take a few minutes. Please wait while this process completes.`;
      return (
        <Message
          contents={contents}
          isThin={true}
        />
      );
    } else {
      return null;
    }
  };

  submitButton = () => {
    return (
      <>
        <a href={cancelUrl()} className='btn btn-link cancel-link'>Cancel</a>
        <Button
          onClick={this.handleFormSubmit}
          label={'Add Time Period'}
        />
      </>
    );
  };

  renderSubmitButton = () => {
    const {
      timePeriodToAdd,
      previousTimePeriod,
      currentStep,
    } = this.state;
    if (timePeriodToAdd && previousTimePeriod == null && currentStep != 4) {
      return this.submitButton();
    }
  };

  renderWizardSubmitButton = () => {
    const {
      timePeriodToAdd,
      previousTimePeriod,
      currentStep,
    } = this.state;
    if (timePeriodToAdd && previousTimePeriod && currentStep == 4) {
      return this.submitButton();
    }
  };

  renderPreviousTimePeriodFieldset = () => {
    const {
      timePeriodToAdd,
      availablePreviousTimePeriods
    } = this.state;
    const anyPreviousTimePeriods = !_.isEmpty(availablePreviousTimePeriods);

    return (
      <>
        <fieldset>
          <legend><span className="text-info text-bold">[Optional]</span> Roll forward data from a previous time period</legend>
          {this.renderHelpMessage(timePeriodToAdd, anyPreviousTimePeriods)}
          {this.renderPreviousTimePeriodSelect()}
          <div id='wizard'>
            <div className='row'>
              <div className='col-md-12'>
                { this.renderRiskCategories() }
                { this.renderRiskCategoryRatings() }
                { this.renderMeasurementPoints() }
                { this.renderConfirmation() }
              </div>
            </div>
          </div>
        </fieldset>
        <div className='form-group'>
          {this.previousButton}
          {this.nextButton}
          <span className='pull-right'>
            {this.renderWizardSubmitButton()}
          </span>
          {this.renderSubmitButton()}
        </div>
      </>
    );
  };

  renderRiskCategories = () => {
    if (!this.state.previousTimePeriod || this.state.currentStep !== 1) {
      return null;
    }

    const {
      previousRiskCategories,
      configurationDisabled
    } = this.state;
    const anyRiskCategories = !_.isEmpty(previousRiskCategories);

    const selectedRiskCategories = this.getSelectedOptions(previousRiskCategories, this.state.selectedRiskCategoryKeys);

    return (
      <>
        <div className='row'>
          <div className='col-md-12'>
            <label className='control-label'>Choose {window.riskEntitySettings.plural_risk_category_label} to Roll Forward</label>
          </div>
        </div>
        <div>
          { anyRiskCategories ?
            <TimePeriodDataTable
              records={previousRiskCategories}
              rowComponent={TimePeriodDataTableRow}
              isCheckable={true}
              isDisabled={configurationDisabled}
              initializeAllChecked={true}
              previouslySelectedKeys={selectedRiskCategories}
              onSelectedRowsChange={this.handleSelectedRiskCategoriesChange}
              headerName={window.riskEntitySettings.plural_risk_category_label}
            />
            : <p>No {window.riskEntitySettings.plural_risk_category_label.toLowerCase()} available.</p>
          }
        </div>
      </>
    );
  };

  renderRiskCategoryRatings = () => {
    if (!this.state.previousTimePeriod || this.state.currentStep !== 2) {
      return null;
    }

    const {
      previousRiskCategories,
      previousRiskSubcategories,
      rollForwardSelectIndicatorsChecked,
      rollForwardRatingJustificationChecked,
      rollForwardRiskAppetiteRatingJustificationChecked,
      rollForwardWeightJustificationChecked,
      rollForwardIndicatorJustificationChecked,
      rollForwardAllJustificationChecked,
      rollForwardSelfAssessmentConsiderationRatingsChecked,
      submitting
    } = this.state;

    const selectedRiskCategories = this.getSelectedOptions(previousRiskCategories, this.state.selectedRiskCategoryKeys);
    const selectedRiskCategoryRatings = this.getSelectedOptions(selectedRiskCategories, this.state.selectedRiskCategoryRatingKeys);
    const selectedRiskSubcategories = this.getSelectedSubcategoryOptions(previousRiskSubcategories, selectedRiskCategories);
    const selectedRiskSubcategoryRatings = this.getSelectedOptions(selectedRiskSubcategories, this.state.selectedRiskSubcategoryRatingKeys);

    return (
      <>
        <div className='row'>
          <div className='col-md-12'>
            <label className='control-label'>Choose Qualitative Ratings to Roll Forward <i className='label-help fa fa-info-circle' data-toggle='tooltip' data-trigger='focus' data-placement='right' data-title='Qualitative Rating refers to any rating assignment not sourced from a KRI.'></i></label>
          </div>
        </div>
        <div>
          { selectedRiskCategories.length != 0 ?
            <TimePeriodDataTable
              records={selectedRiskCategories}
              rowComponent={TimePeriodDataTableRow}
              isCheckable={true}
              isDisabled={submitting}
              initializeAllChecked={false}
              previouslySelectedKeys={selectedRiskCategoryRatings}
              onSelectedRowsChange={this.handleSelectedRiskCategoryRatingsChange}
              headerName='Risk Categories'
              isRating={true}
            />
            : <p>No categories available.</p>
          }
        </div>
        <div>
          { selectedRiskSubcategories.length != 0 &&
          <TimePeriodDataTable
            records={selectedRiskSubcategories}
            rowComponent={TimePeriodDataTableRow}
            isCheckable={true}
            isDisabled={submitting}
            initializeAllChecked={false}
            previouslySelectedKeys={selectedRiskSubcategoryRatings}
            onSelectedRowsChange={this.handleSelectedRiskSubcategoryRatingsChange}
            headerName='Subcategories'
            isRating={true}
          /> }
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-12'>
                <label className='control-label'>Choose to Roll Forward Select Type Indicator Ratings<i className='label-help fa fa-info-circle' data-toggle='tooltip' data-trigger='focus' data-placement='right' data-title='Select Type KRIs will have their ratings copied forward to the new time period.'></i></label>
              </div>
            </div>
            <div>
              <div className='table-light'>
                <table className='table'>
                  <tbody>
                    <tr>
                      <td className='checkbox-width'>
                        <input
                          className='boolean'
                          type='checkbox'
                          checked={rollForwardSelectIndicatorsChecked}
                          onChange={this.handleSelectIndicatorCheckedChange} />
                      </td>
                      <td>Select Type KRI Ratings</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-12'>
                <label className='control-label'>Choose to Roll Forward Self-Assessment Consideration Ratings <i className='label-help fa fa-info-circle' data-toggle='tooltip' data-trigger='focus' data-placement='right' data-title="The Self-Assessment Consideration Ratings will be copied forward to the new time period."></i></label>
              </div>
            </div>
            <div>
              <div className='table-light'>
                <table className='table'>
                  <tbody>
                    <tr>
                      <td className='checkbox-width'>
                        <input
                          className='boolean'
                          type='checkbox'
                          checked={rollForwardSelfAssessmentConsiderationRatingsChecked}
                          onChange={this.handleSelfAssessmentConsiderationRatingsChange} />
                      </td>
                      <td>Self-Assessment Consideration Ratings</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-12'>
                <label className='control-label'>Choose to Roll Forward Justifications<i className='label-help fa fa-info-circle' data-toggle='tooltip' data-trigger='focus' data-placement='right' data-title="Selected Justifications will be copied forward to the new time period's Risk Attributes."></i></label>
              </div>
            </div>
            <div>
              <div className='table-light'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>
                        <input
                          className='boolean'
                          type='checkbox'
                          checked={rollForwardAllJustificationChecked}
                          onChange={this.handleAllJustificationChange} />
                      </th>
                      <th>All Justifications</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='checkbox-width'>
                        <input
                          className='boolean'
                          type='checkbox'
                          checked={rollForwardRatingJustificationChecked}
                          onChange={this.handleRatingJustificationChange} />
                      </td>
                      <td>Attribute Rating Justifications</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          className='boolean'
                          type='checkbox'
                          checked={rollForwardRiskAppetiteRatingJustificationChecked}
                          onChange={this.handleRiskAppetiteRatingJustificationChange} />
                      </td>
                      <td>Attribute Risk Appetite Rating Justifications</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          className='boolean'
                          type='checkbox'
                          checked={rollForwardWeightJustificationChecked}
                          onChange={this.handleWeightJustificationChange} />
                      </td>
                      <td>Attribute Weight Justifications</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          className='boolean'
                          type='checkbox'
                          checked={rollForwardIndicatorJustificationChecked}
                          onChange={this.handleIndicatorJustificationChange} />
                      </td>
                      <td>KRI Justifications</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderMeasurementPoints = () => {
    if (!this.state.previousTimePeriod || this.state.currentStep !== 3) {
      return null;
    }

    const {
      submitting
    } = this.state;

    const measurementPointOptions = this.measurementPointOptions();
    const selectedMeasurementPoints = this.getSelectedOptions(measurementPointOptions, this.state.selectedMeasurementPointsKeys);

    return (
      <>
        <div className='row'>
          <div className='col-md-12'>
            <label className='control-label'>Choose {window.riskEntitySettings.plural_measurement_point_label} to Roll Forward</label>
          </div>
        </div>
        <div>
          { <TimePeriodDataTable
            records={measurementPointOptions}
            rowComponent={TimePeriodDataTableRow}
            isCheckable={true}
            isDisabled={submitting}
            initializeAllChecked={true}
            previouslySelectedKeys={selectedMeasurementPoints}
            onSelectedRowsChange={this.handleSelectedMeasurementPointsChange}
            headerName={window.riskEntitySettings.plural_measurement_point_label}
          />
          }
        </div>
      </>
    );
  };

  renderConfirmation = () => {
    if (!this.state.previousTimePeriod || this.state.currentStep !== 4) {
      return null;
    }

    const {
      previousRiskCategories,
      previousRiskSubcategories,
      rollForwardSelectIndicatorsChecked
    } = this.state;

    const measurementPointOptions = this.measurementPointOptions();

    const selectedRiskCategories = this.getSelectedOptions(previousRiskCategories, this.state.selectedRiskCategoryKeys);
    const selectedRiskCategoryRatings = this.getSelectedOptions(previousRiskCategories, this.state.selectedRiskCategoryRatingKeys);
    const selectedRiskSubcategories = this.getSelectedSubcategoryOptions(previousRiskSubcategories, selectedRiskCategories);
    const selectedRiskSubcategoryRatings = this.getSelectedOptions(selectedRiskSubcategories, this.state.selectedRiskSubcategoryRatingKeys);
    const selectedMeasurementPoints = this.getSelectedOptions(measurementPointOptions, this.state.selectedMeasurementPointsKeys);

    return (
      <>
        <div className='row'>
          <div className='col-md-12'>
            <h4>Confirmation</h4>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div>
              <ConfirmationContainer
                records={[{id: this.state.timePeriodToAdd.label, name: this.state.timePeriodToAdd.label}]}
                rowComponent={DisplayRow}
                headerName={['Time Period to Add']}
              />
            </div>
            <div>
              <ConfirmationContainer
                records={[{id: this.state.previousTimePeriod.label, name: this.state.previousTimePeriod.label}]}
                rowComponent={DisplayRow}
                headerName={['Previous Time Period']}
              />
            </div>
            <div>
              <ConfirmationContainer
                records={selectedRiskCategories}
                rowComponent={DisplayRow}
                headerName={['Selected ' + window.riskEntitySettings.plural_risk_category_label + ' to Roll Forward']}
              />
            </div>
            <div>
              <ConfirmationContainer
                records={selectedRiskCategoryRatings.concat(selectedRiskSubcategoryRatings)}
                rowComponent={DisplayRow}
                headerName={['Selected Qualitative Ratings to Roll Forward']}
              />
            </div>
            <div className="row">
              <div className="col-sm-12">
                <ul className={styles.confirmationList}>
                  <li className={styles.confirmationHeader}>
                    Roll Forward Select Type KRI Ratings
                  </li>
                  <ul className={styles.confirmationItem}>
                    <li>
                      { rollForwardSelectIndicatorsChecked ? 'Yes' : 'No' }
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            <div>
              <ConfirmationContainer
                records={selectedMeasurementPoints}
                rowComponent={DisplayRow}
                headerName={['Selected ' + window.riskEntitySettings.plural_measurement_point_label + ' to Roll Forward']}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const {
      timePeriodToAdd,
      submitting,
      optinChecked,
      optinVisible,
    } = this.state;

    return (
      <form className='panel time-period-form'>
        <div className='panel-body'>
          <fieldset>
            <legend>Which time period do you want to add?</legend>
            <div className='form-group select'>
              <label className='select control-label'>Select time period</label>
              <Select
                id={'timePeriodToAddSelect'}
                classNamePrefix='timePeriod'
                value={timePeriodToAdd}
                onChange={this.handleSelectedTimePeriodChange}
                options={availableTimePeriods}
                isDisabled={submitting}
                styles={{ menuList: (provided, _state) => ({ ...provided, maxHeight: 200 }) }}
              />
            </div>
            {optinVisible &&
              <div className='form-group'>
                <label>
                  <input
                    className='boolean'
                    type='checkbox'
                    checked={optinChecked}
                    onChange={this.handleCheckedChange} />
                  To receive peer average maturity information when available, you must opt in to participate in the data collection process.
                </label>
              </div>
            }
          </fieldset>
          {this.renderPreviousTimePeriodFieldset()}
          <div className='form-group'>
            {this.renderPatienceMessage()}
          </div>
        </div>
      </form>
    );
  }

  handleCheckedChange = (e) => {
    this.setState({ optinChecked: e.target.checked });
  };

  handleSelectIndicatorCheckedChange = (e) => {
    this.setState({ rollForwardSelectIndicatorsChecked: e.target.checked });
  };

  handleRatingJustificationChange = (e) => {
    this.setState({ rollForwardRatingJustificationChecked: e.target.checked }, this.handleAllJustificationChecked);
  };

  handleRiskAppetiteRatingJustificationChange = (e) => {
    this.setState({ rollForwardRiskAppetiteRatingJustificationChecked: e.target.checked }, this.handleAllJustificationChecked);
  };

  handleWeightJustificationChange = (e) => {
    this.setState({ rollForwardWeightJustificationChecked: e.target.checked }, this.handleAllJustificationChecked);
  };

  handleIndicatorJustificationChange = (e) => {
    this.setState({ rollForwardIndicatorJustificationChecked: e.target.checked }, this.handleAllJustificationChecked);
  };

  handleAllJustificationChange = (e) => {
    let isChecked = e.target.checked;
    this.setState({ rollForwardAllJustificationChecked: isChecked });
    this.setState({ rollForwardRatingJustificationChecked: isChecked });
    this.setState({ rollForwardRiskAppetiteRatingJustificationChecked: isChecked });
    this.setState({ rollForwardWeightJustificationChecked: isChecked });
    this.setState({ rollForwardIndicatorJustificationChecked: isChecked });
  };

  handleAllJustificationChecked = () => {
    let allJustificationChecked = this.state.rollForwardRatingJustificationChecked
      && this.state.rollForwardRiskAppetiteRatingJustificationChecked
      && this.state.rollForwardWeightJustificationChecked
      && this.state.rollForwardIndicatorJustificationChecked;
    this.setState({ rollForwardAllJustificationChecked: allJustificationChecked });
  };

  handleSelfAssessmentConsiderationRatingsChange = (e) => {
    this.setState({ rollForwardSelfAssessmentConsiderationRatingsChecked: e.target.checked });
  };
}

const getPreviousTimePeriods = (selectedTimePeriod) => {
  return ax({
    url: Routes.time_period_previous_time_periods_path(selectedTimePeriod.value),
    method: 'get',
  }).then((res) => {
    return res.data;
  }).catch(ax.handleError);
};

const pollUntilReady = (timePeriod) => {
  let url = Routes.time_period_status_path(timePeriod.param);
  const handlePollError = (error) => {
    ax.handleError(error);
    let response = error.response;
    let message = 'AddTimePeriodPollingFailure';
    window.Honeybadger.notify({
      message: message,
      fingerprint: message,
      context: {
        url: url,
        responseStatus: response.status,
        responseData: response.data
      }
    });
  };

  setTimeout(function() {
    ax({
      url: url,
      method: 'get'
    }).then((res) => {
      if (res.data.add_in_progress === false) {
        if (res.data.status <= 0) {
          handlePollError({response: res});
        } else {
          window.location = Routes.edit_time_period_path(timePeriod.param);
        }
      } else {
        pollUntilReady(timePeriod);
      }
    }).catch(handlePollError);
  }, 1000);
};

export default AddTimePeriodContainer;
